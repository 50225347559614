<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import LandingView from "./views/LandingWrapper.vue";
import AppWrapper from "./views/AppWrapper.vue";
import Fail from "./views/fail_modules/FailWrapper.vue";
import audioControl from "./js/audioControl.js";
import loadSequence from "./js/loadSequence.js";
import Debug from "./components/debug_modules/DebugMenu.vue";
import PopupLegalFooter from "./components/modules/PopupLegalFooter.vue";
import analytics from "./js/analytics";

const loading = ref(false);

// grab state
const store = useStore();

function handleContinue() {
  audioControl.restartAudio("guiPositive");
  if (!loading.value) {
    analytics.dataLayerEvent("Link Click", "ar_start", "Read", "Engagement");
    loading.value = true;
    setTimeout(function () {
      document.querySelector(".continue-button").disabled = true;
      // clearTimeout(this.timeout);
      store.dispatch("legalFooterActive", false);
      store.commit("reset");
      loadSequence
              .asyncLoad()
              .catch((error) => {
                console.warn("85", "landing", "loadAndStartScene", error);
              })
              .then(() => {});

      setTimeout(() => {
        audioControl.init();
      }, 300);

      store.commit("setIsLanding", false);
      loading.value = false;
    }, 100)

  }
}
// Variables needed from store
const getAppFailed = computed(() => store.getters.getAppFailed);

const getIsLanding = computed(() => store.getters.getIsLanding);

const getIsRotated = computed(() => store.getters.getIsRotated);

const getIsDesktop = computed(
  () => !store.getters.getIsMobile && !store.getters.getIsTablet
);
</script>

<template>
  <div>
    <Fail v-cloak v-if="getAppFailed" />
    <LandingView
      v-if="getIsLanding && !getIsRotated && !getIsDesktop"
      @handle-continue="handleContinue"
    />
    <AppWrapper
      :store="store"
      v-else-if="!getIsLanding || getIsRotated || getIsDesktop"
    />
    <Debug />
    <PopupLegalFooter :desktop="getAppFailed && getIsDesktop" v-if="getIsLanding" />
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
