<script setup>
import ArWrapper from "./ArWrapper.vue";
import PlacingWrapper from "./PlacingWrapper.vue";
import LoadingWrapper from "./LoadingWrapper.vue";
import PopupLegalFooter from "@/components/modules/PopupLegalFooter.vue";
import InstructionsContainer from "@/components/InstructionsContainer.vue";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const loaded = computed(() => store.getters.getLoaded);
const scanned = computed(() => store.getters.getScanned);
const placed = computed(() => store.getters.getPlaced);
const isFlashing = computed(() => store.getters.getFlashing);

const getAppFailed = computed(() => store.getters.getAppFailed);
const getIsDesktop = computed(() => {
  return !store.getters.getIsMobile && !store.getters.getIsTablet;
});
const isInstructions = computed(() => store.getters.getIsInstructions);
const getWinOverlayEnabled = computed(() => store.getters.getWinOverlayEnabled);
const getResultsOpen = computed(() => store.getters.getResultsOpen);
const getBlockARTap = computed(() => store.getters.getBlockARTap);

const getIsDebugEnabled = computed(() => store.getters.getIsDebugEnabled);
const getLeavingPopupEnabled = computed(
  () => store.getters.getLeavingPopupEnabled
);
const allowGyroPrompt = computed(
  () => loaded && scanned && !getAppFailed && !placed
);
</script>

<template>
  <div
    style="z-index: 2; pointer-events: none"
    class="absolute-fill main-wrapper"
  >
    <transition name="fade">
      <ArWrapper v-if="loaded && placed && !isInstructions" />
    </transition>
    <transition name="fade">
      <PlacingWrapper v-if="loaded && !placed && !isInstructions" />
    </transition>
    <transition name="fade">
      <InstructionsContainer v-if="loaded && !placed && isInstructions" />
    </transition>
    <transition name="camera-flash">
      <div v-if="isFlashing" class="camera-flash" />
    </transition>
    <transition name="fade">
      <LoadingWrapper v-if="!loaded && !getAppFailed" />
    </transition>
  </div>

</template>
