<script setup>
import {onMounted} from "vue";
import analytics from "../../js/analytics";

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
});

onMounted(() => {
  analytics.dataLayerPage("ar_rotated", "");
});
</script>

<template>
  <div class="module-container">
    <div class="content-column is-mobile-rotated" style="margin-bottom: 0">
      <UncopyableImage
        class="image is-mobile-rotated"
        src="../www/assets/imgs/rotate-device.png"
        :width="130"
        :height="130"
        :use-width-scaling="false"
        :mobile-landscape="true"
      />
      <span
        class="text text-header is-mobile-rotated"
        style="margin-bottom: 0"
        v-html="props.title"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.module-container {
  position: relative;
  width: fit-content;
  height: fit-content;

  * {
    pointer-events: all;
  }
}
</style>
