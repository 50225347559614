<template>
  <div class="header complete-center" :class="{ 'is-desktop': getIsDesktop }">
    <a href="https://www.dove.com/us/en/stories/about-dove.html?utm_source=dovelogo&utm_medium=microsite&utm_campaign=protectandrestore&utm_id=greta" target="_blank">
      <UncopyableImage
              class="header-image"
              src="../www/assets/imgs/dove-header.png"
              :width="getWidth"
              :height="getHeight"
              :useDesktopProportion="getIsDesktop"
              :scaleToViewport="!getIsDesktop && !getIsRotated"
      />
    </a>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed } from "vue";
const store = useStore();

const getIsDesktop = computed(() => {
  return !store.getters.getIsMobile && !store.getters.getIsTablet;
});
const getIsRotated = computed(() => {
  return store.getters.getIsRotated;
});
const getWidth = computed(() => {
  if (getIsDesktop)
    return 229.05;
  else if (store.getters.getIsRotated)
    return 136.8;
  else
    return 171;
});
const getHeight = computed(() => {
  if (getIsDesktop)
    return 68.85;
  else if (store.getters.getIsRotated)
    return 41.6;
  else
    return 52;
});
</script>

<style lang="scss" scoped></style>
