export default {
  setLegalFooterActive(state, target) {
    state.legalFooterActive = target;
  },

  setFirstTimeRun(state, target) {
    state.firstTimeRun = target;
  },

  //-----LOADING-----

  setIsLanding: (state, target) => {
    state.isLanding = target;
  },
  setIsInstructions: (state, target) => {
    state.isInstructions = target;
  },
  loadStarted(state) {
    state.loadStarted = true;
  },
  loadComplete(state) {
    // mutate state
    state.loaded = true;
  },

  //-----DEBUG-----

  setIsDebugEnabled(state, target) {
    state.isDebugEnabled = target;
  },

  //-----PERMISSIONS-----

  setGyro(state, target) {
    state.hasGyro = target;
  },
  setCameraPermissionsRequested(state, target) {
    state.cameraPermissionsRequested = target;
  },
  setCameraPermissionsFailed(state, target) {
    state.isCameraPermissionsFailed = target;
  },
  setMotionPermissionsRequested(state, target) {
    state.isMotionPermissionsRequested = target;
  },

  //-----ERRORS-----

  setAppFailed(state, isFailed) {
    state.appFailed = isFailed;
  },
  setIsRotated(state, target) {
    state.isRotated = target;
  },
  setAndroidUnsupported(state, target) {
    state.androidUnsupported = target;
  },
  setUpgradeIOS(state, target) {
    state.upgradeIOS = target;
  },
  setWasCameraAutoRejected(state, target) {
    state.wasCameraAutoRejected = target;
  },

  //-----Device Detect States-----

  setIsChrome(state, isChrome) {
    state.isChrome = isChrome;
  },
  setIsAndroid(state, isAndroid) {
    state.isAndroid = isAndroid;
  },
  setIsMobileSafari(state, isMobileSafari) {
    state.isMobileSafari = isMobileSafari;
  },
  setIsMobile(state, isMobile) {
    state.isMobile = isMobile;
  },
  setIsIOS(state, isIOS) {
    state.isIOS = isIOS;
  },
  setIOSMajorVersion(state, target) {
    state.iOSMajorVersion = target;
  },
  setIOSMinorVersion(state, target) {
    state.iOSMinorVersion = target;
  },
  setIOSVersionCutoff(state, target) {
    state.iOSVersionCutoff = target;
  },
  setIsTablet(state, isTablet) {
    state.isTablet = isTablet;
  },
  setUA(state, ua) {
    state.UA = ua;
  },
  setIsIphoneX(state, target) {
    state.isIPhoneX = target;
  },

  setErrorScreenOverride(state, target) {
    state.errorScreenOverride = target;
  },

  setIsBrowserSupported(state, target) {
    state.isBrowserSupported = target;
  },

  //-----SCANNER-----

  setScanned(state, isScanned) {
    state.scanned = isScanned;
  },
  /*
		setScanning(state, isScanning){
			state.scanning = isScanning;
		},
		setScanFailed(state, isScanFailed){
			state.scanFailed = isScanFailed;
		},
		setScanTarget(state, target){
			state.scanImage = target;
		},
        */

  //-----AR TEMPLATE-----

  setPlaced(state, isPlaced) {
    state.placed = isPlaced;
  },
  setShareOpen(state, isShareOpen) {
    state.shareOpen = isShareOpen;
  },
  setShopOpen(state, isShopOpen) {
    state.shopOpen = isShopOpen;
  },
  setLearnMoreOpen(state, isShopOpen) {
    state.learnMoreOpen = isShopOpen;
  },
  setIsOnboarding(state, target) {
    state.isOnboarding = target;
  },

  //-----PHOTO TAKING-----

  setFlashing(state, isFlashing) {
    state.flash = isFlashing;
  },
  setPhotoOpen(state, isPhotoOpen) {
    state.photoOpen = isPhotoOpen;
  },

  //-----LANGUAGE-----

  setLanguageSelectorEnlarged(state, target) {
    state.isLanguageSelectorEnlarged = target;
  },

  setLocale(state, target) {
    state.locale = target;
  },

  reset(state) {
    state.shareOpen = false;
    state.photoOpen = false;
    state.placed = false;
  },

  //-----SCENE CHANGING-----

  setIsNewSceneLoading(state, target) {
    state.isNewSceneLoading = target;
  },

  //-----SCREEN DIMENSIONS-----
  setTrueVW(state, target) {
    state.trueVW = target;
  },
  setTrueVH(state, target) {
    state.trueVH = target;
  },
};
