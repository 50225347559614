/*
Watermarking sections written by Star LaGrasse
Check drawWatermark functions for explanations
Photocapture currently only works in AFrame context
something in Playcanvas broke photocapture
*/

import AR from "./playcanvasAR.js";
import extraAugmentation from "./modules/extraAugmenation.js";

const photocapture = {
  //sampleCenterUrl: "assets/imgs/ar-overlay.png",
  //sampleBannerUrl: "assets/imgs/sample-watermark-banner.png",
  sampleFooterUrl: "assets/imgs/ar-overlay.png",
  //sampleColumnUrl: "assets/imgs/sample-watermark-column.png",
  //sampleCornerUrl: "assets/imgs/sample-watermark-corner.png",

  async capture(onfinish) {
    /*
    const [sampleCenter] = await Promise.all([
      imagePromise(this.sampleCenterUrl),
    ]);
    const [sampleBanner] = await Promise.all([
      imagePromise(this.sampleBannerUrl),
    ]);
    const [sampleColumn] = await Promise.all([
      imagePromise(this.sampleColumnUrl),
    ]);
    const [sampleCorner] = await Promise.all([
      imagePromise(this.sampleCornerUrl),
    ]);
     */
    const [sampleFooter] = await Promise.all([
      imagePromise(this.sampleFooterUrl),
    ]);

    const drawAllWatermarks = function (ctx) {
      let thickness = ctx.canvas.height * 0.01;
      //drawWatermarkBanner(ctx, sampleBanner, 1, true, thickness);
      //drawWatermarkColumn(ctx, sampleColumn, 1, false, thickness);
      drawWatermarkBanner(ctx, sampleFooter, 1, false);
      //drawWatermarkCenter(ctx, sampleCenter, 0.45);
      //drawWatermarkCorner(ctx, sampleCorner, 0.35, thickness, 100, false);
      //drawBox(ctx, thickness, "#ffffff");
      //drawBox(ctx, ctx.canvas.height * 0.01, "#ffffff");
    };

    if (window.XR8) {
      window.XR8.CanvasScreenshot.takeScreenshot({
        onProcessFrame: ({ ctx }) => {
          AR.forceRenderFrame(); //Only in playcanvasAR atm, make sure to add it to the others because it will throw an error
          //example watermarking logic, each call is drawn on top of the last, so keep this in mind if you have any overlapping watermarks
          drawAllWatermarks(ctx);
        },
      })
        .then((data) => {
          const bytes = atob(data);
          const buffer = new ArrayBuffer(bytes.length);
          const array = new Uint8Array(buffer);

          for (let i = 0; i < bytes.length; i++) {
            array[i] = bytes.charCodeAt(i);
          }

          const blob = new Blob([buffer], { type: "image/jpeg" });
          onfinish(URL.createObjectURL(blob), blob);
        })
        .catch(() => {
          onfinish(null);
        });
    } else {
      const captureCanvas = document.createElement("canvas");

      AR.forceRenderFrame();
      var arImageData = AR.getCanvas().toDataURL("image/png");
      const [arImage] = await Promise.all([imagePromise(arImageData)]);

      var camImageInput = document
        .getElementById("camerafeed")
        .toDataURL("image/png");

      //Camera and ar image urls to images
      const [cameraImage] = await Promise.all([imagePromise(camImageInput)]);

      const imageHeight = cameraImage.height * 3;
      const imageWidth = cameraImage.width * 3;

      //Initialize canvas sizes
      const captureContext = captureCanvas.getContext("2d");
      // const canvasCenter = cameraImage.width / 2;
      // Setup canvas for capture
      captureCanvas.height = imageHeight;
      captureCanvas.width = imageWidth;
      captureCanvas.style.height = `${imageHeight}px`;
      captureCanvas.style.width = `${imageWidth}px`;

      //Fill capture canvas with default white
      captureContext.fillStyle = "#FFF";
      captureContext.fillRect(0, 0, imageWidth, imageHeight);

      //Draw camera image, then ar image overtop
      captureContext.drawImage(cameraImage, 0, 0, imageWidth, imageHeight);

      if (extraAugmentation) {
        if (extraAugmentation.isInitialized) {
          captureContext.drawImage(
            extraAugmentation.canvas,
            0,
            0,
            imageWidth,
            imageHeight
          );
        }
      }

      captureContext.drawImage(arImage, 0, 0, imageWidth, imageHeight);

      drawAllWatermarks(captureContext);

      const watermarkedImage = captureCanvas.toDataURL("image/jpeg", 1.0);
      const watermarkedBlob = await new Promise((resolve) => {
        captureCanvas.toBlob((blob) => {
          resolve(blob);
        });
      });

      //document.removeChild(captureCanvas);
      onfinish(watermarkedImage, watermarkedBlob);
    }
  },
};

const imagePromise = (src) => {
  const image = new Image();
  image.src = src;
  return new Promise((resolve, reject) => {
    image.onload = () => {
      resolve(image);
    };
    image.onerror = reject;
  });
};

//Draws a watermark that stretches horizontally across the top or bottom of your screenshot
const drawWatermarkBanner = (
  ctx /*the capture context*/,
  watermarkImage /*the image being drawn*/,
  widthRatio = 1.0 /*how much of the screenshot's width the watermark should span; defaults to 100%*/,
  onTop = true /*determines if this is a header or footer; defaults to header*/,
  boxThickness = 0 /*if you plan to draw a box around the image, inputting the box thickness will automatically prevent the box from overlapping*/
) => {
  let container = new WatermarkImageContainer(
    ctx,
    watermarkImage,
    widthRatio,
    true,
    boxThickness
  );

  let titleX = container.canvasWidth / 2 - container.desiredWidth / 2;

  ctx.drawImage(
    watermarkImage,
    titleX,
    onTop
      ? 0 + boxThickness
      : container.canvasHeight - container.desiredHeight - boxThickness,
    container.desiredWidth,
    container.desiredHeight
  );
};

//Draws a watermark at an offset from any of the images corners
const drawWatermarkCorner = (
  ctx,
  watermarkImage,
  widthRatio = 1.0 /*first three parameters same as above*/,
  horizOffset = 0,
  vertOffset = 0 /*(in pixels) how far away from the starting corner the watermark should be drawn*/,
  isTop = true /*is the starting corner at the top? defaults to true*/,
  isLeft = true /*is the starting corner at the left? defaults to true*/
) => {
  let container = new WatermarkImageContainer(ctx, watermarkImage, widthRatio);

  let titleX = isLeft
    ? 0 + horizOffset
    : container.canvasWidth - container.desiredWidth - vertOffset;
  let titleY = isTop
    ? 0 + vertOffset
    : container.canvasHeight - container.desiredHeight - vertOffset;
  ctx.drawImage(
    watermarkImage,
    titleX,
    titleY,
    container.desiredWidth,
    container.desiredHeight
  );
};

//Draws a watermark at the exact center of the image; added for convenience
const drawWatermarkCenter = (
  ctx,
  watermarkImage,
  widthRatio = 1.0 /*parameters same as first three of above function*/
) => {
  let container = new WatermarkImageContainer(ctx, watermarkImage, widthRatio);

  let titleX = container.canvasWidth / 2 - container.desiredWidth / 2;
  let titleY = container.canvasHeight / 2 - container.desiredHeight / 2;
  ctx.drawImage(
    watermarkImage,
    titleX,
    titleY,
    container.desiredWidth,
    container.desiredHeight
  );
};

//Draws a watermark that stretches vertically across the left or right of your screenshot
const drawWatermarkColumn = (
  ctx,
  watermarkImage /*first two parameters same as above*/,
  heightRatio = 1.0 /*how much of the screenshot's height your watermark should span; defaults to 100%*/,
  onLeft = true /*is the watermark on the left or right of your image; defaults to left*/,
  boxThickness = 0 /*if you plan to draw a box around the image, inputting the box thickness will automatically prevent the box from overlapping*/
) => {
  let container = new WatermarkImageContainer(
    ctx,
    watermarkImage,
    heightRatio,
    false,
    boxThickness
  );

  let titleY = container.canvasHeight / 2 - container.desiredHeight / 2;
  ctx.drawImage(
    watermarkImage,
    onLeft
      ? 0 + boxThickness
      : container.canvasWidth - container.desiredWidth - boxThickness,
    titleY,
    container.desiredWidth,
    container.desiredHeight
  );
};

//Draws a rectangular border around your screenshot
const drawBox = (
  ctx /*first parameter same as above*/,
  lineThickness = 1 /*(in pixels) how thick the box should be; defaults to 1 pixel*/,
  color = "#000000" /*string value of the color the box should be; defaults to black*/
) => {
  let canvasWidth = ctx.canvas.width;
  let canvasHeight = ctx.canvas.height;

  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.rect(0, 0, lineThickness, canvasHeight);
  ctx.fill();

  ctx.beginPath();
  ctx.rect(canvasWidth - lineThickness, 0, lineThickness, canvasHeight);
  ctx.fill();

  ctx.beginPath();
  ctx.rect(0, 0, canvasWidth, lineThickness);
  ctx.fill();

  ctx.beginPath();
  ctx.rect(0, canvasHeight - lineThickness, canvasWidth, lineThickness);
  ctx.fill();
};

//Used to contain all important data associated with a watermark image
class WatermarkImageContainer {
  constructor(
    ctx /*the capture context*/,
    watermarkImage /*the image being drawn*/,
    ratio /*how much of the screenshot's width or height the watermark should span; defaults to 100%*/,
    isWidth = true /*determines whether ratio is a width or height ratio*/,
    boxThickness = 0 /*if you plan to draw a box around the image, inputting the box thickness will automatically prevent the box from overlapping*/
  ) {
    let width = watermarkImage.naturalWidth; //width of watermark image
    let height = watermarkImage.naturalHeight; //height of watermark image
    this.canvasWidth = ctx.canvas.width; //width of the canvas
    this.canvasHeight = ctx.canvas.height; //height of the canvas

    if (isWidth) {
      this.desiredWidth =
        this.canvasWidth * ratio * (1 - (2 * boxThickness) / this.canvasWidth); //width the watermark should be drawn at
      let ttRatio = width / this.desiredWidth;
      this.desiredHeight = height / ttRatio; //height the watermark should be drawn at
    } else {
      this.desiredHeight =
        this.canvasHeight *
        ratio *
        (1 - (2 * boxThickness) / this.canvasHeight); //height the watermark should be drawn at
      let ttRatio = height / this.desiredHeight;
      this.desiredWidth = width / ttRatio; //width the watermark should be drawn at
    }
  }
}

export default photocapture;
