import Vue, { createApp } from "vue";
import Vuex from "vuex";
import UncopyableImage from "./components/UncopyableImage";
import App from "./App.vue";
import { store as storeApp } from "./store";
import { languageModule, translateFilter } from "./js/language";
import permissions from "./js/permissions";
import compatibility from "./js/compatibility";
import AR from "./js/playcanvasAR.js";
import VueSplide from "@splidejs/vue-splide";
import {
  parsePosition,
  parseAnchoring,
  parseContainerFill,
  parseElementDimensions,
  parseMargins,
  parseElementStyle,
  parsePadding,
  getVH,
  getVW,
} from "./js/styleInjection";

import "./sass/App.scss";
import RadialProgressBar from "vue3-radial-progress";
import analytics from "@/js/analytics";

export const store = new Vuex.Store({
  modules: {
    store: storeApp,
    language: languageModule,
  },
});
const options = {
  color: "#bffaf3",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "left",
  inverse: false,
};
const app = createApp(App).use(store).use(RadialProgressBar);

app.use(VueSplide);

app.component("UncopyableImage", UncopyableImage);

app.mixin({
  methods: {
    translate: translateFilter,
    elementDimensions: parseElementDimensions,
    getPosition: parsePosition,
    elementStyle: parseElementStyle,
    margins: parseMargins,
    anchoring: parseAnchoring,
    padding: parsePadding,
    vw: getVW,
    vh: getVH,
    containerFill: parseContainerFill,
  },
});

app.config.globalProperties.$filters = {
  translateFilter,
  elementDimensions: parseElementDimensions,
  getPosition: parsePosition,
  vw: getVW,
  vh: getVH,
  elementStyle: parseElementStyle,
  margins: parseMargins,
  anchoring: parseAnchoring,
  padding: parsePadding,
  containerFill: parseContainerFill,
  toUSD: function (value) {
    return `$${value}`;
  },
};
app.mount("#app");

window.onload = function () {
 // router.replace({ path: "/" });
  //router.go(-1);
};

window.onhashchange = function () {
  //router.replace({ path: "/" });
  window.location.reload(false);
};
window.timeInApp = 0;
window.started = Date.now();

const setVDimensions = function () {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  let vw = window.innerWidth * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  document.documentElement.style.setProperty("--vw", `${vw}px`);
  document.documentElement.style.setProperty("--font-scale", `1`);
  document.documentElement.style.setProperty("position", "absolute");
  document.body.style.setProperty("position", "absolute");
  store.commit("setTrueVH", vh);
  store.commit("setTrueVW", vw);
};

const setViewportHeight = () => {
  setVDimensions();
  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    setVDimensions();

    window.setTimeout(() => {
      setVDimensions();
    }, 500);
  });
};

//Logic to fix issues with camera feed freezing
const focusedPage = () => {
  window.started = Date.now();

  if (window.newWindow) window.newWindow.close();
  if (document.getElementsByTagName("video")[0])
    document.getElementsByTagName("video")[0].play();
  window.setTimeout(() => {
    if (document.getElementsByTagName("video")[0])
      document.getElementsByTagName("video")[0].play();
  }, 1500);
};

const blurPage = () => {
  window.timeInApp += Date.now() - window.started;
};

window.onbeforeunload = () => {
  window.timeInApp += Date.now() - window.started;

  //TAGGED: Might not need this
  document.querySelector("body").dispatchEvent(new Event("cleanupEverything"));
};

window.addEventListener("focus", focusedPage);
window.addEventListener("blur", blurPage);

// Start the scene and catch any bubbled exceptions
const start = async () => {
  try {
    analytics.init();
    setViewportHeight();
    await store.dispatch("language/fetchLanguage");
    await compatibility.checkDevice();
    permissions.checkOrientation();
    await AR.init();
  } catch (e) {
    console.warn("error", "start", e);
    // store.commit("setGeneralError", true);
    store.commit("setAppFailed", true);
  }
};

start();

// catch any global errors
window.onerror = (e) => {
  console.warn("error", "window", e);
  // store.commit("setGeneralError", true);
  store.commit("setAppFailed", true);
};
