<script setup>
import { onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import audioControl from "../js/audioControl";
import analytics from "../js/analytics";

const store = useStore();
const props = defineProps({
  content: {
    default: [
      {
        title: "Step 1",
        body: "Scan a well-lit surface clear of any objects. Tap to place the AR tree when prompted.",
        img: "../assets/imgs/instruction-step-1.png",
      },
      {
        title: "Step 2",
        body: "Once the AR hotspots appear, tap the hotspots to view content.",
        img: "../assets/imgs/instruction-step-2.png",
      },
      {
        title: "Step 3",
        body: "Explore all the hotspots, then share the experience with others!",
        img: "../assets/imgs/instruction-step-3.png",
      },
    ],
    type: Array,
  },
});

onMounted(() => {
  analytics.dataLayerPage("ar_instructions", "");
});
// onUnmounted(() => {
//   store.commit("setIsInstructions", false);
// });

const closeInstructions = () => {
  audioControl.restartAudio("guiPositive");
  analytics.dataLayerEvent("Link Click", "ar_instruction_ok", "Read", "Engagement");
  setTimeout(function () {
    store.commit("setIsInstructions", false);
  }, 100);
};
</script>

<template>
  <div class="instructions">
    <div class="instructions-title">
      <span v-html="translate('instructions-title')" />
    </div>
    <div class="instructions-body">
      <div
        class="instructions-step-container"
        v-for="(item, index) in props.content"
      >
        <div class="instructions-step">
          <div class="instructions-image">
            <UncopyableImage
              class="header-image"
              :src="item.img"
              :width="104"
              :height="104"
            />
          </div>
          <div class="instructions-step-content">
            <span>{{ item.title }}</span>
            <span>{{ item.body }}</span>
          </div>
        </div>
      </div>
      <div class="dove-button" @click="closeInstructions">
        <span v-html="translate('instructions-btn')" />
      </div>
    </div>
  </div>
</template>

