<script setup>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import audioControl from "../js/audioControl";
import analytics from "../js/analytics";
const props = defineProps({
  mediaURL: { default: "", type: String },
  blobURL: { type: Object },
  isVideo: { default: false, type: Boolean },
  hasNativeShare: { default: true, type: Boolean },
  hasDownload: { default: false, type: Boolean },
  hasTapToSave: { default: false, type: Boolean }
});

const store = useStore();
const photoModeVideo = ref(null);
const photoModeImage = ref(null);
const downloadLink = ref(null);

const refreshImage = function () {
  if (props.isVideo) {
    photoModeVideo.value.src = props.mediaURL;
    photoModeVideo.value.load();
    photoModeVideo.value.onloadeddata = function () {
      photoModeVideo.value.play();
      if (props.hasDownload)
        downloadLink.value.href = props.mediaURL;
    }.bind(this);
  } else {
    photoModeImage.value.src = props.mediaURL;
    if (props.hasDownload)
      downloadLink.value.href = props.mediaURL;
  }
};

const nativeShare = async function () {
  let file = props.isVideo
    ? new File([props.blobURL], "dove-recording.mp4", { type: props.blobURL.type })
    : new File([props.blobURL], "dove-screenshot.jpg", { type: props.blobURL.type });
  let shareData = {
    title: "",
    files: [file],
    text: "",
  };
  analytics.dataLayerEvent(ctConstants.share, "ar_capture_share", "Lead", ctConstants.advocacy);
  if (navigator.canShare && navigator.canShare(shareData)) {
    navigator
      .share(shareData)
      .then(() => {
        console.log("share success");
      })
      .catch((error) => console.log("Sharing failed", error));
  }
};

const downloadButton = function () {
  analytics.dataLayerEvent(ctConstants.downloads, "ar_capture_download", "Interest", "Engagement");
}

const closePhoto = function () {
  store.commit("setPhotoOpen", false);
  audioControl.restartAudio("guiNegative");

  if (iOSMajorVersion.value >= 13) {
    setTimeout(() => {
      // Tell the restart-camera script to stop watching for issues
      window.dispatchEvent(new Event("ensurecameraend"));
    }, 1000);
  }
};

const iOSMajorVersion = computed(() => store.getters.getIOSMajorVersion);
const isIOS = computed(() => store.getters.getIsIOS);
const showLowerButtonArea = computed(
  () => props.hasDownload || props.hasNativeShare
);
const downloadMediaType = computed(
        () => props.isVideo ? "video/mp4" : "image/jpeg"
);
const downloadName = computed(
        () => props.isVideo ? "dove-recording.mp4" : "dove-screenshot.jpg"
);
// Lifecycle functions
onMounted(() => {
  refreshImage();
  analytics.dataLayerPage("ar_media_preview", "");
});
</script>

<template>
  <div class="photo-display-container">
    <div class="absolute-fill faded-camera-feed" v-on:click="closePhoto" />
    <div
      id="photoContainer"
      class="photo-container"
      :class="{
        'has-native-share': showLowerButtonArea,
      }"
    >
      <!--<div class="shadow" />-->
      <img
        id="close-picture-button"
        src="../../public/assets/imgs/icon-close.png"
        v-on:click="closePhoto"
      />
      <video
        v-if="props.isVideo"
        ref="photoModeVideo"
        class="image-display"
        playsinline
        autoplay
        muted
        loop
        :class="{
          'has-native-share': showLowerButtonArea,
        }"
      />
      <img
        v-else
        ref="photoModeImage"
        class="image-display allow-select"
        :class="{
          'has-native-share': props.hasNativeShare,
        }"
      />

      <div class="tap-to-save-container" v-if="!props.isVideo && props.hasTapToSave">
        <div class="background"></div>
        <div
          class="tap-to-save-text"
          v-html="translate('save-photo-default')"
        />
        <div class="ios13-prompt" v-if="isIOS && iOSMajorVersion >= 13">
          <div
            class="ios13-prompt-text"
            v-html="translate('save-photo-ios13+')"
          />
          <UncopyableImage
            class="ios13-prompt-image"
            src="../www/assets/imgs/add-to-photos.png"
            :use-width-percent-scaling="true"
            :width="55"
          />
        </div>
      </div>
    </div>
    <div class="lower-share-container" v-if="showLowerButtonArea" @click="downloadButton">
      <a
              v-if="props.hasDownload"
              ref="downloadLink"
              target="_blank"
              :download="downloadName"
              :type="downloadMediaType"
              class="width-half"
      >
        <div class="dove-button download-button width-half">
          <img class="icon-normal" src="../../public/assets/imgs/icon-download.png"/>
          <img class="icon-tap" src="../../public/assets/imgs/icon-download-tap.png"/>
        </div>
      </a>

      <div class="dove-button share-button"  @click="nativeShare"
           :class="{'width-half': props.hasDownload, 'width-full': !props.hasDownload }">
        <img class="icon-normal" src="../../public/assets/imgs/icon-share.png"/>
        <img class="icon-tap" src="../../public/assets/imgs/icon-share-tap.png"/>
      </div>

    </div>
  </div>
</template>
