///////////////////////////////////////////////////////////////////////////
/*
Name: 			analytics.js

Description:    How to Swap Analytics Type: change the method being assign to this.analyticsMethod

Usage:			import analytics from "./analytics.js"

*/
///////////////////////////////////////////////////////////////////////////

const analytics = {

  init() {
    //console.log("init analytics");
  },

  dataLayerEvent(eventAction, eventLabel, subCategory, category, eventValue) {
    if (!eventValue)
      eventValue = 1;
    console.log("Analytics Event: "+eventAction+"; "+eventLabel+"; "+subCategory+"; "+category+"; "+eventValue);
    if (window.analyticsSetup)
    {
      var ev = {};
      ev.eventInfo={
        'type': 'ctConstants.trackEvent',
        'eventAction': eventAction,
        'eventLabel': eventLabel,
        'eventValue': eventValue,
      };
      ev.category={'primaryCategory': category};
      ev.subcategory = subCategory;
      window.digitalData.event.push(ev)
    }
  },

  dataLayerPage(pageName, content) {
    console.log("Analytics Page: "+pageName+"; "+content);
    if (window.analyticsSetup)
    {
      window.digitalData.page.pageInfo.pageName = pageName; //To be set on each page change
      window.digitalData.page.category.pageType = "Protect and Restore";
      window.digitalData.page.category.subCategory1 = "Protect and Restore – Dove USA";
      window.digitalData.page.category.subCategory2 = "";
      window.digitalData.page.category.subCategory3 = "";
      if (content !== "404" && content !== 404)
        content = "Protect and Restore – Dove USA";
      window.digitalData.page.attributes.contentType = content; // To be set on each page change. Should contain 404 for error pages
      window.digitalData.page.attributes.articleName = "";  //Populate only on Article pages
      window.digitalData.page.attributes.brandCategory = 'Personal Care';
    }
  },
  dataLayerSetDestinationURL(url) {
    if (window.analyticsSetup)
    {
      window.digitalData.page.pageInfo.destinationURL = url;
    }
  }



};

export default analytics;
