<script setup>
  import {onMounted, ref} from "vue";
import { useStore } from "vuex";
import { translateFilter as translate } from "../js/language";
import TitleImage from "./TitleImage.vue";
import audioControl from "../js/audioControl";
  import analytics from "../js/analytics";

const isLinkCopied = ref(false);
const store = useStore();

const close = function () {
  audioControl.restartAudio("guiNegative");
  store.commit("setShopOpen", false);
};

  onMounted(() => {
    analytics.dataLayerPage("ar_shop_menu", "");
  });

  const walmart = function () {

    analytics.dataLayerEvent(ctConstants.retailerClick, "ar_shop_walmart", "Read", ctConstants.conversion);
    analytics.dataLayerSetDestinationURL("https://www.walmart.com/cp/dove/7423532");
  };
  const shopKick = function () {

    analytics.dataLayerEvent(ctConstants.retailerClick, "ar_shop_shopkick", "Read", ctConstants.conversion);
    analytics.dataLayerSetDestinationURL("https://bit.ly/3NBnRFk");
  };

</script>
<template>
  <div class="shop-container">
    <div class="absolute-fill faded-background" @click="close" />
    <div class="shop-background">
      <div class="shop-title">
        <UncopyableImage
          class="shop-hero"
          src="../www/assets/imgs/share-screen-title.png"
          :height="52"
          :width="171"
          @click="close"
        />
      </div>
      <UncopyableImage
        class="btn-close"
        src="../www/assets/imgs/close-share-x.png"
        :height="48"
        :width="48"
        @click="close"
      />
      <div class="header-text">Shop what you care for.</div>
      <div class="body-text">Get kicks on Shopkick by purchasing your favorite Dove products. You can also shop the full Dove portfolio on Walmart.com.</div>
      <a href="https://bit.ly/3NBnRFk" target="_blank">
        <div class="dove-button shop-button-1" @click="shopKick">
          <img class="icon-normal" src="../../public/assets/imgs/shopkick-logo.png"/>
          <img class="icon-tap" src="../../public/assets/imgs/shopkick-logo-white.png"/>
        </div>
      </a>
      <a href="https://www.walmart.com/cp/dove/7423532" target="_blank">
        <div class="dove-button shop-button-2" @click="walmart">
          <img class="icon-normal" src="../../public/assets/imgs/walmart-logo.png"/>
          <img class="icon-tap" src="../../public/assets/imgs/walmart-logo-white.png"/>
        </div>
      </a>
    </div>
  </div>
</template>
