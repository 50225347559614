<template>
  <div class="partner-lockup">
    <UncopyableImage
      class="partner-lockup-image"
      src="../www/assets/imgs/partner-lockup.png"
      :width="126"
      :height="24"
    />
  </div>
</template>

<script setup></script>

<style lang="scss">
.partner-lockup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 40.5px;
}
</style>
