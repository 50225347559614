///////////////////////////////////////////////////////////////////////////
/*
Name: 			language.js

Description: 	Allows us to seamlessly translate all the text between languages.
                Also matches all text in the app to the copy doc regardless of if multiple languages are not supported.

Usage:			Importing the Copy Doc
                    + Get access to the Copy Doc on Google Sheets if you haven't already. Contact your Project Manager.
                    + Tab over to "JSON". Select the entire middle column and copy that.
                    + Under www/assets/lang you should see "content-en-US" for every project. There could be more for different languages.
                    + Paste what you copied from the Copy Doc directly into that file, overriding everything.

                Using "translateFilter" or "translate" to Set Text From the Copy Doc
                    - The method "translateFilter" below is converted into the mixim "translate" so you can use it in vue with this syntax:
                        v-html="translate('copy-doc-section.specific-key')"
                    - v-html indicates your setting the innerhtml.
                    - Note: You don't need to include your function in the start sequence its just a good idea to ensure you have access to things like the store.
                    - Outside of Vue, you have to import translateFilter from './language.js" then call it as a function with the copy doc key as the parameter.

                Changing the Language
                    - Instead of calling a function here, do store.dispatch("changeLocale", locale).
                    - This will eventually call fetchLanguage which will set the language to one specified in the store's "locale" variable.
*/
///////////////////////////////////////////////////////////////////////////

import { store } from "../main.js";
import axios from "axios";
const getInArray = (object, accessorArray) =>
  accessorArray.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), object);
export const getIn = (object, pathString) =>
  pathString ? getInArray(object, pathString.split(".")) : object;

//const BOLD_PATTERN = /(\*)(.*?)\1/g;

export const translateFilter = (input, options) => {
  if (options) {
    console.log("11", "", "translateFilter", options);
  }
  const translation = input && store.getters["language/getTranslation"](input);

  if (translation) {
    let result = translation.replace(/\n/g, "<br/>");
    //.replace(BOLD_PATTERN, "<strong>$2</strong>");
    if (options && options.image) {
      result = result.replace(
        /\[IMAGE\]/g,
        `<img src="${options.image}" class="${options.class}" />`
      );
    }
    return result;
  }
  return input;
};

const state = { translations: null };
const mutations = {
  translations: (state, value) => {
    state.translations = value;
  },
};

const actions = {
  async fetchLanguage({ commit }) {
    //console.log("fetching lang "+store.getters.getLocale)
    document.documentElement.setAttribute("lang", store.getters.getLocale);
    // removed className, use lang psuedo selector p:lang(en-US){}
    // document.documentElement.classList.add(store.getters.getLocale);
    const url = `./assets/lang/content-${store.getters.getLocale}.json`;
    try {
      const response = await axios.get(url);
      commit("translations", response.data);
      return response.data;
    } catch (error) {
      alert(`Could not load language file ${url}\n${error.message}`);
    }
  },
};

const getters = {
  isLoaded: (state) => !!state.translations,
  getTranslation: (state) => (key) => getIn(state.translations, key),
  locale: () => {
    if (window.locale != undefined) {
      return window.locale;
    } else {
      return "en-US";
    }
    /*if (router.currentRoute.query && !!router.currentRoute.query.locale) {
			return router.currentRoute.query.locale
		} else {
			return 'en-US'
		}*/
  },
};

export const languageModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
