<script setup>
  import {onMounted} from "vue";
  import analytics from "../../js/analytics";

  onMounted(() => {
    analytics.dataLayerPage("ar_desktop", 0);
  });
</script>

<template>
  <div class="module-container">
    <div class="content-column is-desktop" style="margin-bottom: 0">
      <div class="desktop-title">
        <span v-html="translate('error-screens-desktop-title')" />
        <span v-html="translate('error-screens-desktop-title-2')" />
      </div>
      <div class="qr-code">
        <span
          class="text is-desktop"
          v-html="translate('error-screens-desktop-error-body')"
        />
        <UncopyableImage
          class="image is-desktop"
          src="../www/assets/imgs/qr-code.png"
          :width="140"
          :height="140"
          :use-desktop-proportion="true"
          :use-width-scaling="false"
          :scale-to-viewport="true"
        />
      </div>
      <div class="desktop-warning">
        <span
          class="text text-header is-desktop"
          v-html="translate('error-screens-desktop-error-body-2')"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.module-container {
  position: relative;
  width: fit-content;
  height: fit-content;

  * {
    pointer-events: all;
  }
}
</style>
