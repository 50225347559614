<script setup>
// Props
const props = defineProps({
  src: { type: String, default: "" },
  width: { type: [Number, String], required: true },
  height: { type: [Number, String], required: true },
  scaleToViewport: { type: Boolean, default: true },
  mobileLandscape: { type: Boolean, default: false },
  useDesktopProportion: { type: Boolean, default: false },
  useWidthScaling: { type: Boolean, default: true },
  useWidthPercentScaling: { type: Boolean, default: false },
  useHeightPercentScaling: { type: Boolean, default: false },
  fillContainer: { type: Boolean, default: false },
  verticalFillAnchor: { type: String, default: "center" },
  horizontalFillAnchor: { type: String, default: "center" },
  slotJustify: { type: String, default: "inherit" },
  slotAlign: { type: String, default: "inherit" },
});

const validateSource = (source) => {
  source = source.replace("../www", ".");
  while (source.includes("../")) source = source.replace("../", "");
  return source;
};
</script>

<template>
  <div
    class="uncopyable-image"
    @click="$emit('click')"
    :style="
      !props.fillContainer
        ? $filters.elementDimensions({
            width: props.width,
            height: props.height,
            mobileDimension: !props.useDesktopProportion,
            horizontalWidthScaling: props.useWidthScaling,
            verticalWidthScaling: props.useWidthScaling,
            mobileLandscape: props.mobileLandscape,
            scaleToViewport: props.scaleToViewport,
            widthPercentScaling: props.useWidthPercentScaling,
            heightPercentScaling: props.useHeightPercentScaling,
          })
        : 'width:100%; height: 100%'
    "
  >
    <img
      :src="validateSource(src)"
      :style="
        !props.fillContainer
          ? $filters.elementDimensions({
              width: props.useWidthPercentScaling ? 100 : props.width,
              height: props.useHeightPercentScaling ? 100 : props.height,
              mobileDimension: !props.useDesktopProportion,
              horizontalWidthScaling: props.useWidthScaling,
              verticalWidthScaling: props.useWidthScaling,
              mobileLandscape: props.mobileLandscape,
              scaleToViewport: props.scaleToViewport,
              widthPercentScaling: props.useWidthPercentScaling,
              heightPercentScaling: props.useHeightPercentScaling,
              fitContent: false,
            })
          : $filters.containerFill({
              verticalAnchor: props.verticalFillAnchor,
              horizontalAnchor: props.horizontalFillAnchor,
              preserveAspectRatio: true,
            })
      "
      :width="props.width"
      :height="props.height"
      oncontextmenu="return false;"
    />
    <div class="slot-holder complete-center">
      <slot />
    </div>
  </div>
</template>
