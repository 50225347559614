<script setup>
  import {inject, defineProps, onMounted} from 'vue'
  import analytics from "../../js/analytics";
  const props = defineProps({
    hasCautionIcon: {
      type: Boolean,
      default: true,
    },
    info: {
      type: Array,
      array: true,
      default: () => [],
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    isMobileRotated: {
      type: Boolean,
      default: false,
    },
    screenName: {
      type: String,
      default: "ar_error"
    },
  });

  onMounted(() => {
    analytics.dataLayerPage(props.screenName, 404);
  });
</script>

<template>
  <div class="module-container">
    <div
      class="content-column"
      style="margin-bottom: 0"
      :class="{
        'is-desktop': props.isDesktop,
        'is-mobile-rotated': props.isMobileRotated,
      }"
    >
      <UncopyableImage
        class="image"
        src="../www/assets/imgs/caution-icon.png"
        v-if="props.hasCautionIcon"
        :width="props.isDesktop ? 105 : 56"
        :height="props.isDesktop ? 105 : 56"
        :use-desktop-proportion="props.isDesktop"
        :use-width-scaling="!props.isDesktop && !props.isMobileRotated"
        :mobile-landscape="props.isMobileRotated"
        :class="{
          'is-desktop': props.isDesktop,
          'is-mobile-rotated': props.isMobileRotated,
        }"
      />
      <div v-for="(object, index) in info" v-bind:key="index"
           class="text"
           :class="object.class ? 'text-'+object.class : ''"
           v-html="object.html ? object.html : translate(object.text)">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.module-container {
  position: relative;
  width: fit-content;
  height: fit-content;

  * {
    pointer-events: all;
  }
}
</style>
