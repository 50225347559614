///////////////////////////////////////////////////////////////////////////
/*
Name: 			loadSequence.js

Description: 	Controls the load sequence after the user clicks the continue button. In general does gyro and camera check before loading the AR.

Usage:			import loadSequence from "./loadSequence.js" then call loadSequence.asyncLoad() when the user clicks continue.
                Also possible to start this loadSequence at the end of app.js's start function if the app doesn't have a landing page.
*/
///////////////////////////////////////////////////////////////////////////
import { store } from "../main";
import permissions from "./permissions";

import AR from "./playcanvasAR.js";
//import extraAugmentation from "./modules/extra-augmenation";

//const skipGyroCheck = () => getQueryParam('debugGyro') === '1'

const loadSequence = {
  async asyncLoad() {
    store.commit("loadStarted");
    await permissions.gyroCheck();

    if (!store.getters.getAppFailed)
      await permissions.requestCameraPermissions();
    if (!store.getters.getAppFailed)
      await AR.loadScene(
        () => {
          document
            .querySelector("body")
            .dispatchEvent(new Event("startSceneAnim"));

          store.commit("loadComplete");
        },
        () => {}
      );
  },
};

export default loadSequence;
