///////////////////////////////////////////////////////////////////////////
/*
Name: 			audioControl.js

Description: 	Controls all the audio in the app.

Usage:			import audioControl from "./audioControl.js"

                To play a sound, call "restartAudio" with the sound's key as a parameter.

                To add a sound, under "audio" add a new entry with the key you want to assign that sound to and "new Audio("./assets/audio/audioPathHere")"

                Playing a Sound without a click in web is not possible so we have a hack for that!
                On user first click (usually the "continue" button) we play all the sounds listed under "doesntPlayOnClick" and mute them so we can unmute them later.

                the "startListeners" section is the place to put all listeners that start sounds.
*/
///////////////////////////////////////////////////////////////////////////

const AUDIO_MASTER_VOLUME = 1;

const audioControl = {
    initialized: false,

    audio: {
        guiPositive: new Audio("./assets/audio/ui-gui-positive.mp3"),
        guiNegative: new Audio("./assets/audio/ui-gui-negative.mp3"),
        camera: new Audio("./assets/audio/ui-camera-shutter.mp3"),
    },

    doesntPlayOnClick: [],

    loopIntervals: {},
    loopDurations: {},

    //Call init on user input to start all sounds on mute
    init() {
        if (!this.initialized) {
            this.initialized = true;
            for (
                let audioIndex = 0;
                audioIndex < this.doesntPlayOnClick.length;
                audioIndex++
            ) {
                let audio = this.audio[this.doesntPlayOnClick[audioIndex]];
                if (audio !== null && audio !== undefined) {
                    audio.muted = true;
                    audio.volume = AUDIO_MASTER_VOLUME;
                    audio.play();
                }
            }
        }
    },
    //Add listeners to events that call audio to play
    startListeners() {
    },

    restartAudio(audioName) {
        let audioFile = this.audio[audioName];
        if (audioFile !== null && audioFile !== undefined) {
            audioFile.muted = false;
            audioFile.currentTime = 0;
            audioFile.play();
        }
    },

    stopSound(audioName) {
        let audioFile = this.audio[audioName];
        if (audioFile !== null && audioFile !== undefined) {
            audioFile.muted = true;
        }
    },

    startLoopingAudio(audioName, audioLength) {
        let audioFile = this.audio[audioName];

        if (audioFile !== null && audioFile !== undefined) {
            if (this.loopIntervals[audioName] != null)
                clearInterval(this.loopIntervals[audioName]);
            audioFile.muted = false;
            audioFile.currentTime = 0;
            audioFile.play();
            this.loopDurations[audioName] = audioLength;
            this.loopIntervals[audioName] = setInterval(function () {
                audioFile.currentTime = 0;
                audioFile.play();
            }, audioLength);
        }
    },
    stopLoopingAudio(audioName) {
        let audioFile = this.audio[audioName];

        if (audioFile !== null && audioFile !== undefined) {
            let audioFile = this.audio[audioName];

            audioFile.muted = true;
            audioFile.currentTime = 0;
            clearInterval(this.loopIntervals[audioName]);
            delete this.loopIntervals[audioName];
            delete this.loopDurations[audioName];
        }
    },
    pauseAllLoopingAudio() {
        for (const name in this.loopIntervals) {
            if (this.loopIntervals[name] !== null) {
                let audioFile = this.audio[name];

                audioFile.muted = true;
                audioFile.currentTime = 0;
                clearInterval(this.loopIntervals[name]);
            }
        }
    },
    restartAllLoopingAudio() {
        for (const name in this.loopIntervals) {
            if (this.loopIntervals[name] !== null)
                this.startLoopingAudio(name, this.loopDurations[name]);
        }
    },
    fadeOutLoop(audioName, time) {
        let audioFile = this.audio[audioName];
        audioFile.muted = false;
        var oldLoopingInterval = this.loopIntervals[audioName];
        var fade = setInterval(function () {
            if (audioFile.volume - 0.05 > 0)
                audioFile.volume = audioFile.volume - 0.05;
            else audioFile.volume = 0;
        }, time / 20);
        setTimeout(
            function () {
                audioFile.muted = true;
                audioFile.currentTime = 0;
                clearInterval(oldLoopingInterval);
                clearInterval(fade);
                delete this.loopIntervals[audioName];
                delete this.loopDurations[audioName];
            }.bind(this),
            time
        );
    },
};

export default audioControl;
