<script setup>
import { computed } from "vue";

import FailDesktop from "./FailDesktopGeneric";
import FailRotated from "./FailRotated";
import FailGeneric from "./FailGeneric";
import FailList from "./FailList";
import BasicLegalFooter from "@/components/modules/BasicLegalFooter.vue";
import { useStore } from "vuex";
import Header from "@/components/Header.vue";
const store = useStore();
const motionRefresh = () => {
  store.commit("setMotionPermissionsCancelled", false);
  store.commit("setAppFailed", false);
};

const cameraAutoRejected = computed(
  () => store.getters.getWasCameraAutoRejected
);
const isIOS = computed(() => store.getters.getIsIOS);
const hasGyro = computed(() => store.getters.getGyro);
const isAndroid = computed(() => store.getters.getIsAndroid);

const camPermissionsDenied = computed(
  () => store.getters.getCameraPermissionsFailed
);
const isChrome = computed(() => store.getters.getIsChrome);
const isRotated = computed(() => store.getters.getIsRotated);

const upgradeIOS = computed(() => store.getters.getUpgradeIOS);
const override = computed(() => store.getters.getErrorScreenOverride);

const legalFooterActive = computed(() => store.getters.getLegalFooterActive);
const supportedBrowser = computed(() => store.getters.getIsBrowserSupported);
const iOSMajorVersion = computed(() => store.getters.getIOSMajorVersion);
const iOSVersionCutoff = computed(() => store.getters.getIOSVersionCutoff);
const overrideDevice = computed(() => {
  if (override.value.substring(0, 3) === "ios") return "ios";
  else if (override.value.substring(0, 7) === "android") return "android";
  else return "";
});
const getIsDesktop = computed(() => {
  return !store.getters.getIsMobile && !store.getters.getIsTablet;
});
</script>

<template>
  <div class="fail-container">
    <div
      class="view-window"
      :class="{
        'is-desktop': getIsDesktop,
        'is-mobile-rotated': !getIsDesktop && isRotated,
      }"
    >
      <Header />
      <div
        class="error-panel"
        :class="{
          'is-desktop': getIsDesktop,
        }"
      >
        <UncopyableImage
          v-if="getIsDesktop"
          class="desktop-background-image"
          src="../www/assets/imgs/desktop-background.png"
          :width="1750"
          :height="931"
          :useDesktopProportion="getIsDesktop"
          :scaleToViewport="false"
          :fillContainer="true"
        />
        <!-- Header Image -->

        <!-- Rotation Error -->
        <FailRotated
          class="module"
          v-if="isRotated"
          :title="translate('error-screens-rotate-device')"
        />

        <!-- Desktop Error -->
        <FailDesktop class="module" v-else-if="getIsDesktop" />

        <!-- iOS Specific Errors -->
        <!-- iOS Specific Errors -->
        <div v-else-if="(isIOS && overrideDevice !== 'android') || overrideDevice === 'ios'">
          <!-- Incompatible Browser -->
          <FailGeneric
                  class="module"
                  v-if="!supportedBrowser || override === 'iosIncompatibleBrowser'"
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  screenName="ar_ios_incompatible_browser"
                  :info="[
                    {text: 'error-screens-android-error-header', class: 'header'},
                    {html: '<b>'+translate('web-ar-url')+'</b>', class: 'link'},
                    {text: 'error-screens-android-incompatible-browser-body-2'}
            ]"
          />

          <!-- Motion Permissions Denied (General) -->
          <FailGeneric
                  class="module"
                  v-else-if="(!hasGyro && iOSMajorVersion !== 12) || override === 'iosMotionDenied13'"
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  screenName="ar_ios_motion_denied_13"
                  :info="[
                    {text: 'motion-and-camera-denied-header', class: 'header'},
                    {text: 'motion-and-camera-denied-body-1'}
            ]"
          />

          <!-- Motion Permissions Denied (iOS 12) -->
          <FailList
                  class="module"
                  v-else-if="(!hasGyro && iOSMajorVersion === 12) || override === 'iosMotionDenied122'"
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  screenName="ar_ios_motion_denied_122"
                  :title="translate('error-screens-motion-sensor-request-header')"
                  :list-items="[
              {
                itemText: translate('error-screens-ios-sensor-request-p1'),
                imageSource: '../www/assets/imgs/ios-settings-icon.png',
                imageWidth: 30,
                imageHeight: 30,
              },
              {
                itemText: translate('error-screens-ios-sensor-request-p2'),
                imageSource: '../www/assets/imgs/safari-icon.png',
                imageWidth: 30,
                imageHeight: 30,
              },
              {
                itemText: translate('error-screens-ios-sensor-request-p3'),
              },
              {
                itemText: translate('error-screens-ios-sensor-request-p4'),
                imageSource: '../www/assets/imgs/error-screen-reload-icon.png',
                imageWidth: 30,
                imageHeight: 32,
              },
            ]"
          />

          <!-- Camera Auto Denied -->
          <FailList
                  class="module"
                  v-else-if="cameraAutoRejected || override === 'cameraAutoReject'"
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  screenName="ar_ios_camera_auto_reject"
                  :title="translate('error-screens-cam-setup-header')"
                  :list-items="[
              {
                itemText: translate('error-screens-cam-setup-p1'),
              },
              {
                itemText: translate('error-screens-cam-setup-p2'),
              },
              {
                itemText: translate('error-screens-cam-setup-p3'),
              },
              {
                itemText: translate('error-screens-cam-setup-p4'),
              },
            ]"
          />

          <!-- Upgrade OS (Generic) -->
          <FailGeneric
                  class="module"
                  v-else-if="iOSMajorVersion < iOSVersionCutoff || override === 'iosUpgrade'"
                  :is-desktop="getIsDesktop"
                  screenName="ar_ios_upgrade"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  :info="[
                    {text: 'error-screens-ios-incompatible-version-header', class: 'header'},
                    {text: 'error-screens-ios-incompatible-version-body'}
            ]"
          />

          <!-- Generic Error -->
          <FailGeneric
                  class="module"
                  v-else
                  :is-desktop="getIsDesktop"
                  screenName="ar_ios_generic_error"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  :info="[
                    {text: 'error-screens-general-error', class: 'header'},
                    {text: 'error-screens-general-error-body'}
            ]"
          />
        </div>
        <!-- Android Specific Errors -->
        <div
                v-else-if="(isAndroid && overrideDevice !== 'ios') || overrideDevice === 'android'"
        >
          <!-- Camera Denied (Generic) -->
          <FailList
                  class="module"
                  v-if="(camPermissionsDenied && !isChrome) || override === 'androidCameraDeniedSamsung'"
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  screenName="ar_android_camera_denied_samsung"
                  :title="translate('error-screens-android-camera-req-header')"
                  :list-items="[
              {
                itemText: translate('error-screens-samsung-camera-req-p1'),
              },
              {
                itemText: translate('error-screens-samsung-camera-req-p2'),
              },
              {
                itemText: translate('error-screens-samsung-camera-req-p3'),
              },
              {
                itemText: translate('error-screens-samsung-camera-req-p4'),
              },
              {
                itemText: translate('error-screens-samsung-camera-req-p5'),
              },
              {
                itemText: translate('error-screens-samsung-camera-req-p6'),
              },
              {
                itemText: translate('error-screens-samsung-camera-req-p7'),
              },
            ]"
          />

          <!-- Camera Denied (Chrome) -->
          <FailList
                  class="module"
                  v-else-if="(camPermissionsDenied && isChrome) || override === 'androidCameraDenied'"
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  screenName="ar_android_camera_denied"
                  :title="translate('error-screens-android-camera-req-header')"
                  :list-items="[
              {
                itemText: translate('error-screens-samsung-camera-req-p1'),
              },
              {
                itemText: translate('error-screens-samsung-camera-req-p2'),
              },
              {
                itemText: translate('error-screens-android-camera-req-p3'),
              },
              {
                itemText: translate('error-screens-android-camera-req-p4'),
              },
              {
                itemText: translate('error-screens-android-camera-req-p5'),
              },
              {
                itemText: translate('error-screens-android-camera-req-p6'),
              },
              {
                itemText: translate('error-screens-android-camera-req-p7'),
              },
            ]"
          />

          <!-- Incompatible Browser -->
          <FailGeneric
                  class="module"
                  v-else-if="!supportedBrowser || override === 'androidIncompatibleBrowser'"
                  :is-desktop="getIsDesktop"
                  screenName="ar_android_incompatible_browser"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  :info="[
                    {text: 'error-screens-android-error-header', class: 'header'},
                    {html: '<b>'+translate('web-ar-url')+'</b>', class: 'link'},
                    {text: 'error-screens-android-incompatible-browser-body-2'}
            ]"
          />

          <!-- Generic Error -->
          <FailGeneric
                  class="module"
                  v-else
                  :is-desktop="getIsDesktop"
                  screenName="ar_android_generic_error"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  :info="[
                    {text: 'error-screens-general-error', class: 'header'},
                    {text: 'error-screens-general-error-body'}
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
