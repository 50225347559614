<script setup>
import { onMounted } from "vue";
import audioControl from "../js/audioControl.js";
import AR from "../js/playcanvasAR.js";
import { useStore } from "vuex";
import analytics from "../js/analytics";

const store = useStore();
onMounted(() => {
  audioControl.startListeners();
  AR.restartPlace(setPlaced);
  analytics.dataLayerPage("ar_placing", "");
});

const setPlaced = () => {
  if (!store.getters.getPlaced) {
    AR.app.fire("tree-appear");
    analytics.dataLayerEvent("Link Click", "ar_tree_place", "Read", "Engagement");
    audioControl.restartAudio("guiPositive");
    store.commit("setPlaced", true);
  }
};
</script>

<template>
  <div>
    <transition name="animate-opacity">
      <div class="place-container absolute-fill">
        <div class="place-popup">
          <span v-html="translate('placing-popup')" />
        </div>
      </div>
    </transition>
  </div>
</template>
