<script setup>
import { ref, computed, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { translateFilter } from "../../js/language";
import analytics from "../../js/analytics";

// grab store
const store = useStore();

const hasOpened = ref(false);

// Computed props
const isActive = computed(() => store.getters.getLegalFooterActive);
const isRotated = computed(() => store.getters.getIsRotated);
const isMobile = computed(() => store.getters.getIsMobile);

const props = defineProps({
  desktop: { default: false, type: Boolean }
});

const toggleActive = (val) => {
  hasOpened.value = true;
  store.commit(
    "setLegalFooterActive",
    val !== undefined ? val : !isActive.value
  );
  console.log("update isActive");
  console.log(isActive.value);
  if (isActive.value)
    analytics.dataLayerEvent(ctConstants.anchorLinkClicked, "ar_legal_open", "Read", "Engagement");
  else
    analytics.dataLayerEvent(ctConstants.anchorLinkClicked, "ar_legal_close", "Read", "Engagement");
};
const getLink = (textKey, linkKey) => {
  var text = translateFilter(textKey);
  var link = translateFilter(linkKey);

  return `<a href='${link}' target="_blank">${text}</a>`;
};

// Lifecycle functions
onBeforeMount(() => {
  console.log(store.getters.legalFooterActive);
  store.commit("setLegalFooterActive", false);
});
onMounted(() => {

  setTimeout(() => {
    if (!hasOpened.value)
    {
      store.commit("setLegalFooterActive", true);
      hasOpened.value = true;
      setTimeout(() => {
        hasOpened.value = false;
        store.commit("setLegalFooterActive", false);
      }, 2000);
    }
  }, 3000);

});

const terms = function () {
  analytics.dataLayerSetDestinationURL(translateFilter('terms-conditions-url'));
}
const privacy = function () {
  analytics.dataLayerSetDestinationURL(translateFilter('privacy-policy-url'));
}
const accessibility = function () {
  analytics.dataLayerSetDestinationURL(translateFilter('legal-footer-link-1-url'));
}
const donotshare = function () {
  analytics.dataLayerSetDestinationURL(translateFilter('legal-footer-link-2-url'));
}
</script>

<template>
  <div class="popup-wrapper">
    <div class="lockup-wrapper" v-if="!isRotated && !appFailed" />
    <div
      v-if="!isRotated"
      class="popup-legal-footer"
      :class="{
        appFailed: appFailed,
        isActive: isActive || props.desktop,
        loaded: loaded,
        desktop: props.desktop
      }"
    >
      <div
        class="legal-footer-main"
        :class="{ isActive: isActive || props.desktop }"
      >
        <div
          class="mainContentArea"
          :class="{ isActive: isActive || props.desktop }"
        >
          <div class="hiderArea" @click="toggleActive()" v-if="!props.desktop">
            <div class="hiderAreacontent">
              <span class="barText" v-html="translate('legal-footer-1')" />
              <div class="btnClose-container">
                <div v-if="isActive" class="btnClose">
                  <UncopyableImage
                    class="header-image"
                    :width="10"
                    :height="5"
                    src="../www/assets/imgs/footer-close-expand-btn.png"
                  />
                </div>
                <div v-if="!isActive" class="btnOpen">
                  <UncopyableImage
                    class="header-image"
                    :width="10"
                    :height="5"
                    src="../www/assets/imgs/footer-close-expand-btn.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mainContent">
            <div class="mainContentWrapper">
              <img src="../../../public/assets/imgs/lets-change-beauty-dove-lockup.png" class="lets-change-beauty"/>
              <div class="link-text" style="margin-top: 2.33vh;" v-html="translate('legal-footer-copywrite')"></div>
              <div class="mainContent-links">
                  <a class="link-text"
                    v-bind:href="translate('privacy-policy-url')"
                    v-html="translate('privacy-policy-text')"
                     target="_blank"
                     @click="privacy"
                  />
                &nbsp;
                &nbsp;
                &nbsp;
                <a class="link-text"
                   v-bind:href="translate('terms-conditions-url')"
                   v-html="translate('terms-conditions-text')"
                   target="_blank"
                   @click="terms"
                />
                &nbsp;
                &nbsp;
                &nbsp;
                <a class="link-text"
                   v-bind:href="translate('legal-footer-link-1-url')"
                   v-html="translate('legal-footer-link-1')"
                   target="_blank"
                   @click="accessibility"
                />
                &nbsp;
                &nbsp;
                &nbsp;
                  <a class="link-text"
                    v-bind:href="translate('legal-footer-link-2-url')"
                    v-html="translate('legal-footer-link-2')"
                     target="_blank"
                     @click="donotshare"
                  />
                &nbsp;
                &nbsp;
                &nbsp;
                <span class="evidon-notice-link"></span>
                <!--
                <span style="white-space: nowrap">
                  <img src="../../../public/assets/imgs/ad-choice-logo.png" class="ad-choice-logo"/>
                  &nbsp;
                  <a class="link-text"
                     v-bind:href="translate('legal-footer-link-adchoices-url')"
                     v-html="translate('legal-footer-link-adchoices')"
                  />
                </span>
                -->
              </div>
              <div class="united-states-message" v-html="translate('legal-footer-usa-only-message')">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../sass/Settings";
.popup-wrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  color: black;

  .popup-legal-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    z-index: 11;
    pointer-events: all;
    transform: translateY(79.5vw);
    transition: transform 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-aspect-ratio: 1224/1898) {
      transform: translateY(78vw);
    }
    &.loaded {
      transform: translateY(79.5vw);
      @media (min-aspect-ratio: 1224/1898) {
        transform: translateY(78vw);
      }
    }
    &.isActive {
      transform: translateY(0);
    }
    &.appFailed {
      z-index: 11;
      .legal-footer-persistent {
        background-color: #f1f1f2;
      }
      .legal-footer-main {
        background-color: #f1f1f2;
        .mainContentArea {
          background-color: #f1f1f2;
          .mainContent {
            background-color: #f1f1f2;
          }
        }
      }
    }
    .ad-choice-logo {
      width: vw(14);
      height: auto;
      position: relative;
      top: vw(3);
      display: inline;
    }
    .legal-footer-main {
      transition: all 0.25s ease-in-out 0s;
      height: 100%;

      width: 100%;
      background-color: white;

      &.isActive {
        height: 100%;
      }

      .mainContentArea {
        width: 100%;
        top: 0;
        height: 100%;
        .hiderArea {
          position: relative;
          height: vw(21);
          width: 100%;
          pointer-events: auto;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background: #f1f1f2;
          border-bottom: 1px solid #767676;
          .hiderAreacontent {
            width: auto;
            display: flex;
            .btnClose-container {
              display: flex;
              align-items: center;
              padding-bottom: 2px;
              .btnOpen,
              .btnClose {
                .bg-image {
                  height: 9px;
                  width: 18px;
                }
              }
            }
          }
        }

        .mainContent {
          position: relative;
          width: 100%;
          height: 100%;
          background-color: #001f5f;
          display: flex;
          flex-direction: column;
          align-items: center;
          .mainContentWrapper {
            width: 90%;
            .lets-change-beauty {
              width: vw(237);
              height: auto;
              margin-top: vw(32);
            }
            .link-text {
              font-family: "sofia-reg";
              font-size: vw(13);
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.31;
              letter-spacing: vw(0.13);
              text-align: center;
              color: #fff;
              white-space: nowrap;
            }
            .united-states-message {
              font-family: "sofia-reg";
              font-size: vw(12);
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: vw(0.12);
              text-align: center;
              color: #fff;
              width: 85%;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: vw(32);
            }
            .mainContent-links {
              margin-bottom: vw(5);
            }

          }
        }
      }
    }
    &,
    * {
      box-sizing: border-box;
    }

    .barText {
      padding-bottom: 0.5px;
      font-family: "sofia-reg";
      font-size: vw(11);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: vw(26);
      letter-spacing: vw(0.11);
      text-align: center;
      color: #767676;
    }
    .btnOpen,
    .btnClose {
      margin: 0 vh(7);
      transition: none !important;
      pointer-events: auto;
      // position: relative;
      // bottom: 2.7px;
      margin-right: 5px;
      display: flex;
      align-items: flex-end;
      transform: rotate(180deg);
    }
    .btnClose {
      transform: rotate(0deg);
    }
  }

  .evidon-notice-link {
    display: block;

    .evidon-consent-link-image {
      width: vw(14);
      height: auto;
      position: relative;
      top: vw(3);
      display: inline;
      vertical-align: unset !important;
    }
    .evidon-consent-link-text {

      font-family: "sofia-reg";
      font-size: vw(13);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.31;
      letter-spacing: vw(0.13);
      text-align: center;
      color: #fff;
      white-space: nowrap;
    }
  }

}

.popup-legal-footer.desktop {
  transition: none;
  .lets-change-beauty {
    height: vh(23, $design-height-desktop) !important;
    width: auto  !important;
    margin-top: vh(32, $design-height-desktop) !important;
  }
  .link-text {
    font-size: vh(13, $design-height-desktop) !important;
    letter-spacing: vh(0.13, $design-height-desktop) !important;
  }
  .ad-choice-logo {
    width: vh(14, $design-height-desktop) !important;
    top: vh(3, $design-height-desktop) !important;
  }
  .united-states-message {
    font-size: vh(12, $design-height-desktop) !important;
    letter-spacing: vh(0.12, $design-height-desktop) !important;
    width: vh(557, $design-height-desktop) !important;
    margin-bottom: vh(32, $design-height-desktop) !important;
  }
  .mainContent-links {
    margin-bottom: vh(12, $design-height-desktop) !important;
  }
  .evidon-notice-link {
    display: inline !important;

    .evidon-consent-link-image {
      width: vh(14, $design-height-desktop) !important;
      top: vh(3, $design-height-desktop) !important;
    }
    .evidon-consent-link-text {
      font-size: vh(13, $design-height-desktop) !important;
      letter-spacing: vh(0.13, $design-height-desktop) !important;
    }
  }
}
</style>
