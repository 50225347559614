<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();
const motionRequested = computed(
  () => store.getters.getMotionPermissionsRequested
);
const cameraRequested = computed(
  () => store.getters.getCameraPermissionsRequested
);
const cameraFailed = computed(() => store.getters.getCameraPermissionsFailed);
</script>
<template>
  <div class="perm-popup-area">
    <div class="relative-inner">
      <transition name="fade">
        <div
          class="camera-popup-requested"
          v-if="motionRequested || (cameraRequested && !cameraFailed)"
        >
          <UncopyableImage
            class="camera-popup-image"
            src="../www/assets/imgs/perm-popup.png"
            :width="56"
            :height="56"
          />
          <div class="text-container">
            <div
              class="text-main"
              v-html="translate('motion-and-camera-header')"
            />
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div
          class="camera-popup-requested"
          v-if="cameraRequested && !cameraFailed"
        >
          <UncopyableImage
            class="camera-popup-image"
            src="../www/assets/imgs/perm-popup.png"
            :width="56"
            :height="56"
          />
          <div class="text-container">
            <div
              class="text-main"
              v-html="translate('motion-and-camera-header')"
            />
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="camera-popup-failed" v-if="cameraFailed">
          <UncopyableImage
            class="camera-popup-image"
            src="../www/assets/imgs/perm-popup.png"
            :width="56"
            :height="56"
          />
          <div class="text-container">
            <div
              class="text-main"
              v-html="translate('motion-and-camera-refresh')"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
