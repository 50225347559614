<script setup>
import { onMounted, onUnmounted, ref, computed } from "vue";
import StartPageContainer from "../components/StartPage.vue";
import Header from "../components/Header.vue";
import Debug from "../components/debug_modules/DebugMenu.vue";
import analytics from "../js/analytics";

const timeout = ref(null);

onUnmounted(() => clearTimeout(timeout.value));

onMounted(async () => {
  analytics.dataLayerPage("ar_landing", "");
});

</script>

<template>
  <div class="landing-page">
    <Header />
    <div>
      <StartPageContainer>
        <div
          @click.once="$emit('handle-continue')"
          class="continue-button dove-button"
          style="margin-left: auto; margin-right: auto;"
        >
          <span>EXPLORE</span>
          <!--
          <UncopyableImage
            class="btn complete-center"
            src="../www/assets/imgs/launch-btn.png"
            :width="170"
            :height="56"
          >
            <span>Explore</span>
          </UncopyableImage>
          -->
        </div>
      </StartPageContainer>
    </div>
  </div>
</template>
