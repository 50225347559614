<script setup>
import { ref, onMounted, computed } from "vue";
import photocapture from "../js/photoCapture.js";
import audioControl from "../js/audioControl.js";
import Share from "../components/ShareMenu.vue";
import Shop from "../components/ShopMenu.vue";
import { useStore } from "vuex";
import PhotoDisplay from "../components/PhotoDisplay.vue";
import LearnMorePopup from  "../components/LearnMorePopup.vue";

import AR from "../js/playcanvasAR.js";
import sequencer from "../js/modules/sequencer.js";

import Shutter from "../components/modules/ShutterButton";

import carouselData from "../data/carouselData.json";
import InstructionsContainer from "@/components/InstructionsContainer";
import analytics from "../js/analytics";

// grab state
const store = useStore();
const mediaURL = ref("");
const blobURL = ref("");
const mediaIsVideo = ref(false);
const mediaLoading = ref(false);
const pages = ref(carouselData.pages);

const loading = ref(false);

onMounted(async () => {
  analytics.dataLayerPage("ar_main", "");
});

const recenter = () => {
  AR.recenter();
};
const restart = () => {
  if (mediaLoading.value)
    return;
  AR.app.fire("restart-app");
  AR.app.fire("stop-sound", SoundType.EFFECT, "pangolin-footsteps");
  AR.app.fire("stop-sound", SoundType.EFFECT, "turtle-footsteps");
  AR.app.fire("stop-sound", SoundType.EFFECT, "bird-flap");
  AR.app.fire("stop-sound", SoundType.MUSIC, "rainforest");
  audioControl.restartAudio("guiNegative");
  store.commit("setPlaced", false);
  store.commit("setFirstTimeRun", false);
  store.commit("setPlaced", false);
};
const share = () => {
  if (mediaLoading.value)
    return;
  analytics.dataLayerEvent("Link Click", "ar_share", "Read", "Engagement");
  audioControl.restartAudio("guiPositive");
  store.commit("setShareOpen", true);
};
const shop = () => {
  if (mediaLoading.value)
    return;
  analytics.dataLayerEvent(ctConstants.purchase, "ar_shop_now", "Lead", ctConstants.conversion);
  audioControl.restartAudio("guiPositive");
  store.commit("setShopOpen", true);
};
const learnMore = () => {
  if (mediaLoading.value)
    return;
  analytics.dataLayerEvent("Link Click", "ar_learn_more", "Read", "Engagement");
  audioControl.restartAudio("guiPositive");
  store.commit("setLearnMoreOpen", true);
};
const takePhoto = () => {
  audioControl.restartAudio("camerashutter");
  mediaLoading.value = true;
  analytics.dataLayerEvent("Link Click", "ar_camera_photo", "Read", "Engagement");
  if (store.getters.getPhotoOpen) {
    store.commit("setPhotoOpen", false);
  } else {
    photocapture.capture((url, blob) => {
      if (url != null) {
        mediaURL.value = url;
        blobURL.value = blob;
        mediaIsVideo.value = false;
        store.commit("setPhotoOpen", true);
        mediaLoading.value = false; //Theres no fail safe if photocapture fails, might want to fix that
      }
    });
  }
};

const startVideo = () => {
  console.log("starting video");
  AR.forceRenderFrame();
  AR.app.fire("start-video-recording", { onFinish: onVideoProcessed });
};
const stopVideo = () => {
  mediaLoading.value = true;
  AR.app.fire("stop-video-recording");
};
const onVideoProcessed = ({ videoBlob }) => {
  console.log(videoBlob);
  mediaLoading.value = false;
  let file = new File([videoBlob], "dove-recording.mp4", { type: "video/mp4" });
  mediaURL.value = URL.createObjectURL(file);
  blobURL.value = videoBlob;
  mediaIsVideo.value = true;
  store.commit("setPhotoOpen", true);
  analytics.dataLayerEvent("Link Click", "ar_camera_video", "Read", "Engagement");
};
const onCarouselSelected = (index) => {
  console.log("fired from emit");
  pc.Application.getApplication().fire("changeAnimation", { index });
};

const isPhotoDisplayOpen = computed(() => store.getters.getPhotoOpen);
const shareOpen = computed(() => store.getters.getShareOpen);
const shopOpen = computed(() => store.getters.getShopOpen);
const learnMoreOpen = computed(() => store.getters.getLearnMoreOpen);
const isNewSceneLoading = computed(() => store.getters.getIsNewSceneLoading);
const getIsIOS = computed(() => store.getters.getIsIOS);
</script>

<template>
  <div class="ar-container absolute-fill" id="ar">

    <img src="../../public/assets/imgs/ar-overlay.png" class="ar-overlay"/>
    <!-- All bottom anchored buttons -->
    <transition name="fade">
      <div class="bottom-icons">
        <div class="link-buttons">
          <UncopyableImage
                  v-on:click="shop"
                  src="../www/assets/imgs/shop-now-bttn.png"
                  width="88"
                  height="30"
          />
          <UncopyableImage
                  v-on:click="share"
                  src="../www/assets/imgs/icon-share-bttn.png"
                  width="88"
                  height="30"
          />
          <UncopyableImage
                  v-on:click="learnMore"
                  src="../www/assets/imgs/learn-more-bttn.png"
                  width="88"
                  height="30"
          />
        </div>


        <!-- Shutter Button -->
        <Shutter
          @take-photo="takePhoto"
          @start-video="startVideo"
          @stop-video="stopVideo"
          :allow-video="true"
          :loading="mediaLoading"
        />
      </div>
    </transition>
    <transition name="fade">
      <!-- Restart button & Restart hint -->
      <div class="button-restart" ontouchstart="" v-on:click="restart">
        <UncopyableImage
          src="../www/assets/imgs/icon-restart.png"
          width="28.6"
          height="32"
        />
      </div>
    </transition>

    <!-- Fullscreen popup modals -->
    <transition name="fade">
      <Share v-if="shareOpen && !isPhotoDisplayOpen" />
    </transition>
    <transition name="fade">
      <Shop v-if="shopOpen && !isPhotoDisplayOpen" />
    </transition>
    <transition name="fade">
      <LearnMorePopup v-if="learnMoreOpen && !isPhotoDisplayOpen" />
    </transition>

    <PhotoDisplay
      :mediaURL="mediaURL"
      :blobURL="blobURL"
      :isVideo="mediaIsVideo"
      :has-download="!getIsIOS"
      v-if="isPhotoDisplayOpen"
    />
    <transition name="fade">
      <div class="faded-background" v-if="isNewSceneLoading"></div>
    </transition>
  </div>
</template>
