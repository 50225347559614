<script setup>
  import {onMounted, ref} from "vue";
import { useStore } from "vuex";
import { translateFilter as translate } from "../js/language";
import TitleImage from "./TitleImage.vue";
import audioControl from "../js/audioControl";
  import analytics from "../js/analytics";

const isLinkCopied = ref(false);
const store = useStore();

const close = function () {
  audioControl.restartAudio("guiNegative");
  store.commit("setLearnMoreOpen", false);
};

  onMounted(() => {
    analytics.dataLayerPage("ar_learn_more", "");
  });

</script>
<template>
  <div class="learn-more-container">
    <div class="absolute-fill faded-background" @click="close" />
    <div class="learn-more-background">
      <div class="learn-more-title">
        <UncopyableImage
          class="shop-hero"
          src="../www/assets/imgs/share-screen-title.png"
          :height="52"
          :width="171"
          @click="close"
        />
      </div>
      <UncopyableImage
        class="btn-close"
        src="../www/assets/imgs/close-share-x.png"
        :height="48"
        :width="48"
        @click="close"
      />
      <div class="scroll-area">
        <div class="header-text-bold">How Dove is helping to protect and restore forests’ real beauty</div>
        <div class="header-text-regular">From caring for your skin, to caring for the Earth’s. We’re on a mission to protect and restore nature’s real beauty. In partnership with the Rimba Collective, Dove is protecting and restoring 123,000 acres of rainforests in Southeast Asia over five years.</div>
        <div class="body-text-regular">We’re continually working to reduce the impact of our products on nature and her resources, from becoming a PETA certified brand in 2018 to launching 100% recycled bottles in 2019. But we know we need to do more than just reduce our impact. That’s why, in partnership with the Rimba Collective, we’re investing in the protection and restoration of rainforests in Southeast Asia over five years.</div>
        <div class="body-text-bold">Why are rainforests important?</div>
        <div class="body-text-regular">When it comes to looking after our planet, the importance of trees and rainforests can’t be overstated. Tropical rainforests cover around 8% of the world’s land surface and contain over half of Earth’s animal and plant species. They are full of vivid and diverse beauty and play a practical role in climate change mitigation. By absorbing carbon dioxide (that would otherwise live in the atmosphere) and releasing oxygen, which we depend on for our survival, rainforests help stabilize the Earth’s climate.</div>
        <div class="body-text-bold">Our partnership with the Rimba Collective</div>
        <div class="body-text-regular">The Rimba Collective is an innovative, scalable initiative working to protect forests and restore landscapes in Southeast Asia. We’re working with them as part of the Unilever Climate and Nature Fund, a €1 billion commitment enabling investments in projects taking meaningful and decisive action to fight climate change and regenerate nature.  Our partnership with the Rimba Collective to protect and restore forests’ real beauty will be operated by local NGOs with established track records of working hand-in-hand with communities and local governments. The Rimba Collective helps project operators and communities to maintain conservation outcomes year after year – so you can trust we’ll stay on track to meet our forest conservation targets.</div>
        <div class="body-text-regular">Let’s start caring for the planet like we care for ourselves.</div>
        <!--
        <div class="body-text-regular">Find out more about how we’re taking action to protect our beautiful planet here.</div>
        -->

      </div>
    </div>
  </div>
</template>
