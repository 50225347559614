<script setup>
  import {onMounted, ref} from "vue";
import { useStore } from "vuex";
import { translateFilter as translate } from "../js/language";
import TitleImage from "./TitleImage.vue";
import audioControl from "../js/audioControl";
import analytics from "../js/analytics";

const isLinkCopied = ref(false);
const store = useStore();

  onMounted(() => {
    analytics.dataLayerPage("ar_share", "");
  });

const close = function () {
  audioControl.restartAudio("guiNegative");
  store.commit("setShareOpen", false);
};
const copyLink = function (text) {
  // this.$store.dispatch("userInactionTimeout")
  if (!navigator.clipboard) {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    var oldContentEditable = el.contentEditable,
      oldReadOnly = el.readOnly,
      range = document.createRange();

    el.contentEditable = true;
    el.readOnly = false;
    range.selectNodeContents(el);

    var s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 100);

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;

    document.execCommand("copy");
    document.body.removeChild(el);
  } else {
    navigator.clipboard.writeText(text).then(
      () => {},
      () => {}
    );
  }

  isLinkCopied.value = true;

  setTimeout(() => {
    isLinkCopied.value = false;
  }, 2000);
};

const handleShare = function (type) {
  const shareUrl = translate("web-ar-url");
  const encodedUrl = encodeURI(shareUrl);
  let url = "";
  switch (type) {
    case "facebook": {
      url = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
      analytics.dataLayerEvent(ctConstants.clickstosocialplatforms, "ar_share_facebook", "Lead", "Engagement");
      //this.$store.dispatch('fireAnalytics', 'fb');
      break;
    }
    case "twitter": {
      const text = encodeURI(
          shareUrl
      );
      url = `https://twitter.com/intent/tweet?text=${text}`;
      //this.$store.dispatch('fireAnalytics', 'tw');
      analytics.dataLayerEvent(ctConstants.clickstosocialplatforms, "ar_share_twitter", "Lead", "Engagement");
      break;
    }
    case "email": {
      const subject = encodeURI(`${translate("share-email-title")}`);
      const body = encodeURI(`${translate("share-email-body")} ${shareUrl}`);
      url = `mailto:?subject=${subject}&body=${body}`;
      analytics.dataLayerEvent(ctConstants.clickstosocialplatforms, "ar_share_email", "Lead", "Engagement");
      break;
    }
    case "copy": {
      copyLink(shareUrl);
      analytics.dataLayerEvent(ctConstants.clickstosocialplatforms, "ar_share_link_copy", "Lead", "Engagement");
      break;
    }
    default:
      break;
  }

  if (url) {
    window.open(url, "_blank");
  }
};
</script>
<template>
  <div class="share-container">
    <div class="absolute-fill faded-background" @click="close" />
    <div class="share-background">
      <div class="share-title">
        <UncopyableImage
          class="share-hero"
          src="../www/assets/imgs/share-screen-title.png"
          :height="52"
          :width="171"
          @click="close"
        />
      </div>
      <UncopyableImage
        class="btn-close"
        src="../www/assets/imgs/close-share-x.png"
        :height="48"
        :width="48"
        @click="close"
      />
      <div class="header">
        <UncopyableImage
          class="share-hero"
          src="../www/assets/imgs/landing-hero.png"
          :height="179"
          @click="close"
        />
        <div class="text" v-html="translate('share-title')" />
      </div>

      <div class="footer row-center-center layer-fill">
        <a class="btn" @click="handleShare('twitter')">
          <UncopyableImage
            src="../www/assets/imgs/twitter.png"
            :width="48"
            :height="48"
          />
        </a>
        <a class="btn" @click="handleShare('facebook')">
          <UncopyableImage
            src="../www/assets/imgs/fb.png"
            :width="48"
            :height="48"
          />
        </a>
        <a class="btn" @click="handleShare('email')">
          <UncopyableImage
            src="../www/assets/imgs/email-icon.png"
            :width="48"
            :height="48"
          />
        </a>

        <a
          class="btn btn-copy-link relative"
          @click="handleShare('copy')"
          :class="{
            isLinkCopied,
          }"
        >
          <UncopyableImage
            src="../www/assets/imgs/link.png"
            v-if="!isLinkCopied"
            :width="48"
            :height="48"
          />
          <div class="layer-fill info" v-else>
            <span v-html="translate('share-copied')" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
