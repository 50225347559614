export default {
  getLegalFooterActive(state) {
    return state.legalFooterActive;
  },
  firstTimeRun: (state) => {
    return state.firstTimeRun;
  },

  //-----LOADING-----

  getIsLanding: (state) => {
    return state.isLanding;
  },
  getIsInstructions: (state) => {
    return state.isInstructions;
  },
  getLoaded: (state) => {
    return state.loaded;
  },
  getLoadStarted: (state) => {
    return state.loadStarted;
  },

  //-----DEBUG-----

  getIsDebugEnabled: (state) => {
    return state.isDebugEnabled;
  },

  //-----PERMISSIONS-----

  getGyro: (state) => {
    return state.hasGyro;
  },
  getCameraPermissionsRequested: (state) => {
    return state.cameraPermissionsRequested;
  },
  getCameraPermissionsFailed: (state) => {
    return state.isCameraPermissionsFailed;
  },
  getMotionPermissionsRequested: (state) => {
    return state.isMotionPermissionsRequested;
  },

  //-----ERRORS-----

  getAppFailed: (state) => {
    return state.appFailed || !state.hasGyro || state.isRotated;
  },
  getIsRotated: (state) => {
    return state.isRotated;
  },
  getUpgradeIOS: (state) => {
    return state.upgradeIOS;
  },
  getAndroidUnsupported: (state) => {
    return state.androidUnsupported;
  },
  getWasCameraAutoRejected: (state) => {
    return state.wasCameraAutoRejected;
  },

  //-----Device Detect States-----

  getIsChrome: (state) => {
    return state.isChrome;
  },
  getIsAndroid: (state) => {
    return state.isAndroid;
  },
  getIsMobile: (state) => {
    return state.isMobile;
  },
  getIsIOS: (state) => {
    return state.isIOS;
  },
  getIsTablet: (state) => {
    return state.isTablet;
  },
  getUA: (state) => {
    return state.UA;
  },
  getHasDeviceTest: (state) => !!state.UA,
  getIOSMajorVersion: (state) => {
    return state.iOSMajorVersion;
  },
  getIOSMinorVersion: (state) => {
    return state.iOSMinorVersion;
  },
  getIOSVersionCutoff: (state) => {
    return state.iOSVersionCutoff;
  },
  getIsIphoneX: (state) => {
    return state.isIPhoneX;
  },
  getIsMobileSafari: (state) => {
    return state.isMobileSafari;
  },

  getErrorScreenOverride: (state) => {
    return state.errorScreenOverride;
  },

  getIsBrowserSupported: (state) => {
    return state.isBrowserSupported;
  },

  //-----SCANNER-----

  getScanned: (state) => {
    return state.scanned;
  },
  /*
        getScanning: state => {
            return state.scanning;
        },
        getScanFailed: state => {
            return state.scanFailed;
        },
        getScanTarget: state => {
            return state.scanImage;
        },
         */

  //-----AR TEMPLATE-----

  getPlaced: (state) => {
    return state.placed;
  },
  getShopOpen: (state) => {
    return state.shopOpen;
  },
  getLearnMoreOpen: (state) => {
    return state.learnMoreOpen;
  },
  getShareOpen: (state) => {
    return state.shareOpen;
  },
  getIsOnboarding: (state) => {
    return state.isOnboarding;
  },

  //-----PHOTO TAKING-----

  getFlashing: (state) => {
    return state.flash;
  },
  getPhotoOpen: (state) => {
    return state.photoOpen;
  },

  //-----LANGUAGE-----
  /*
        getLanguageSelectorEnlarged: state => {
            return state.isLanguageSelectorEnlarged;
        },
         */
  getLocale: (state) => {
    return state.locale;
  },

  //-----SCENE CHANGING-----

  getIsNewSceneLoading: (state) => {
    return state.isNewSceneLoading;
  },

  //-----SCREEN DIMENSIONS-----
  getTrueVW: (state) => {
    return state.trueVW;
  },
  getTrueVH: (state) => {
    return state.trueVH;
  },
  getDesignWidthMobile: (state) => {
    return state.designWidthMobile;
  },
  getDesignHeightMobile: (state) => {
    return state.designHeightMobile;
  },
  getDesignWidthDesktop: (state) => {
    return state.designWidthDesktop;
  },
  getDesignHeightDesktop: (state) => {
    return state.designHeightDesktop;
  },
};
