<script setup>
import { ref, watch } from "vue";
const ShutterState = Object.freeze({
  DEFAULT: 0,
  DISABLED: 1,
  RECORDING: 2,
});

const props = defineProps({
  width: {
    type: [String, Number],
    default: 0,
  },
  height: {
    type: [String, Number],
    default: 0,
  },
  enabled: {
    type: Boolean,
    default: true,
  },
  allowVideo: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  }
});

const completedSteps = ref(0);
const totalSteps = ref(10);

const emit = defineEmits(["start-video", "stop-video", "take-photo"]);

const videoMaxLength = ref(10000);
const videoHoldThreshold = ref(500);
const videoStartCheckTimer = ref(null);
const videoAutoEndTimer = ref(null);
const shutterStartTime = ref(0);
// const videoStartTime = ref(0);
// const videoFillAmount = ref(null);
const currentState = ref(ShutterState.DEFAULT);

const onShutterDown = (evt) => {
  if (currentState.value == ShutterState.DISABLED || props.loading) {
    evt.preventDefault();
    return;
  }

  clearVideoStartTimer();
  shutterStartTime.value = new Date().getTime();
  if (props.allowVideo) {
    videoStartCheckTimer.value = setTimeout(
      onShutterHoldThreshold,
      videoHoldThreshold.value
    );
  }
};

const onShutterHoldThreshold = () => {
  if (currentState.value == ShutterState.DISABLED || props.loading) return;
  emit("start-video");
  currentState.value = ShutterState.RECORDING;
  // Might not want to call onShutterUp directly, but it works for current purposes
  videoAutoEndTimer.value = setTimeout(onShutterUp, videoMaxLength.value);
};

const onShutterUp = (evt) => {
  if (currentState.value == ShutterState.DISABLED || props.loading) {
    evt.preventDefault();
    return;
  }
  clearVideoStartTimer();
  clearVideoAutoEndTimer();
  if (currentState.value == ShutterState.RECORDING || props.loading) {
    emit("stop-video");
    currentState.value = ShutterState.DEFAULT;
  } else if (
    new Date().getTime() - shutterStartTime.value <
    videoHoldThreshold.value
  ) {
    emit("take-photo");
  }
};

const clearVideoStartTimer = () => {
  if (videoStartCheckTimer.value) {
    clearTimeout(videoStartCheckTimer.value);
    videoStartCheckTimer.value = null;
  }
};

const clearVideoAutoEndTimer = () => {
  if (videoAutoEndTimer.value) {
    clearTimeout(videoAutoEndTimer.value);
    videoAutoEndTimer.value = null;
  }
};

watch(props.enabled, () => {
  return {
    enabled: {
      immediate: true,
      handler: function (newValue) {
        console.log("setup anebld " + newValue);
        currentState.value = newValue
          ? ShutterState.DEFAULT
          : ShutterState.DISABLED;
      },
    },
  };
});
</script>

<template>
  <div
    class="shutter-button"
    @touchstart="onShutterDown"
    @touchend.prevent="onShutterUp"
  >
    <div
      class="record-circle-area"
      v-if="currentState == ShutterState.RECORDING"
    >
      <div class="clip-center"></div>
      <div class="circle">
        <div class="bar-left">
          <div class="progress"></div>
        </div>
        <div class="bar-right">
          <div class="progress"></div>
        </div>
      </div>
    </div>
    <div class="shutter-center">
    </div>
    <transition name="fade">
      <div
        v-if="currentState == ShutterState.DISABLED"
        class="shutter-image-disabled"
        key="inactive"
      ></div>
      <div v-else-if="currentState == ShutterState.RECORDING" class="shutter-image-record"></div>
      <div v-else class="shutter-image" key="active"></div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
@import "../../sass/Settings";
@import "../../sass/GlobalClasses";

$shutter-width: 116;
$shutter-height: 116;
$record-max-time: 10; //make sure to change this in the component data
$record-max-time-half: $record-max-time/2 + 0s;
.shutter-button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: vh(70);
  height: vh($shutter-width);
  width: vh($shutter-height);
  pointer-events: auto;
  * {
    pointer-events: none;
  }
  .shutter-center {
    position: absolute;
    width: vh(78);
    height: vh(78);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: #ffffff;
    z-index: 1;
  }
  .shutter-image {
    @extend %absolute-fill;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: vh(88);
    height: vh(88);
    background-image: url("../../../public/assets/imgs/btn-camera-ring.png");
  }
  .shutter-image-disabled {
    @extend %absolute-fill;
  }
  .shutter-image-record {
    @extend %absolute-fill;
    background-image: url("../../../public/assets/imgs/btn-camera-record.png");
  }
  .record-circle-area {
    z-index: 1;
    @extend %absolute-fill;

    .clip-center {
      z-index: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: vh(53*2);
      height: vh(53*2);
      border-radius: 50%;
      background-color: #dddddd;
    }
    .circle {
      .progress {
        position: absolute;
        height: 100%;
        width: 100%;
        -webkit-border-radius: 100%;
        clip: rect(0px, vh($shutter-width/2), vh($shutter-height), 0px);
        background: #e60101;
      }
      .bar-left {
        position: absolute;
        height: 100%;
        width: 100%;
        -webkit-border-radius: 100%;
        clip: rect(
          0px,
          vh($shutter-width),
          vh($shutter-height),
          vh($shutter-height/2)
        );

        .progress {
          animation: left $record-max-time-half linear both;
        }

        @keyframes left {
          100% {
            transform: rotate(180deg);
          }
        }
      }
      .bar-right {
        position: absolute;
        height: 100%;
        width: 100%;
        -webkit-border-radius: 100%;
        clip: rect(
          0px,
          vh($shutter-width),
          vh($shutter-height),
          vh($shutter-height/2)
        );
        transform: rotate(180deg);

        .progress {
          animation: right $record-max-time-half linear both;
          animation-delay: $record-max-time-half;
        }

        @keyframes right {
          100% {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
</style>
