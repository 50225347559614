<script setup>
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
// grab state
const store = useStore();

const props = defineProps({
  hasTitle: { default: true, type: Boolean },
});

const animationStage = ref(0);

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

onMounted(async () => {
  await sleep(200);
  animationStage.value = 1;
  await sleep(300);
  animationStage.value = 2;
  await sleep(200);
  animationStage.value = 3;
  await sleep(200);
  animationStage.value = 4;
  await sleep(200);
  animationStage.value = 5;
  await sleep(200);
  animationStage.value = 6;
  await sleep(200);
  animationStage.value = 7;
});
</script>

<template>
  <div class="start-page-container">
    <div class="start-page-container-inner">
      <div class="start-page-container-image">
        <transition name="fade">
          <img v-if="animationStage >= 1"
                  src="../../public/assets/imgs/landing-hero.png"
                  class="landing-hero"
          />
        </transition>

      </div>
      <div class="start-page-container-content">
        <div class="start-page-content-heading">

          <transition name="fade">
            <span v-if="animationStage >= 2"
                  class="text-main"
                  v-html="translate('landing-content-title')"
            />
          </transition>

          <transition name="fade">
            <span v-if="animationStage >= 3"
                  class="text-main"
                  v-html="translate('landing-content-sub-title')"
            />
          </transition>

          <transition name="fade">
          </transition>



        </div>
        <div class="start-page-content-body">

          <transition name="fade">
            <span  v-if="animationStage >= 4"
                 v-html="translate('landing-content-body-1')" />
          </transition>
          <transition name="fade">
            <span  v-if="animationStage >= 5"
                 v-html="translate('landing-content-body-2')" />
          </transition>
          <transition name="fade">
            <span  v-if="animationStage >= 6"
                 v-html="translate('landing-content-body-3')" />
          </transition>
        </div>
        <transition name="fade">
          <slot v-if="animationStage >= 7" />
        </transition>
      </div>
    </div>
    <div class="start-page-container-footer" />
  </div>
</template>
