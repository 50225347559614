<script setup>
import { ref, onMounted, onUnmounted, onUpdated } from "vue";
import PartnerLockup from "@/components/modules/PartnerLockup.vue";
import PermissionPopup from "../components/PermissionsPopup.vue";
import analytics from "../js/analytics";

const progressInterval = ref(null);

const completedSteps = ref(0);
const totalSteps = ref(10);

onMounted(() => {
  analytics.dataLayerPage("ar_loading", "");
  if (completedSteps.value === 0) {
    progressInterval.value = setInterval(() => {
      completedSteps.value += 1;
    }, 100);
  }
});
onUnmounted(() => {
  if (progressInterval.value !== null) {
    clearTimeout(progressInterval.value);
    completedSteps.value = 0;
  }
});
</script>

<template>
  <div class="loader-container absolute-fill">
    <div class="loader-container-content">
      <UncopyableImage
              class="loader-container-image"
              src="../www/assets/imgs/loading.gif"
              :width="145"
              :height="145"
      />
      <radial-progress-bar
        :diameter="215"
        :completed-steps="completedSteps"
        :total-steps="totalSteps"
        :innerStrokeColor="'#dddddd'"
        :innerStrokeWidth="2"
        :startColor="'#001f5f'"
        :stopColor="'#001f5f'"
        :strokeWidth="2"
        :animateSpeed="5000"
      >
      </radial-progress-bar>
    </div>

    <PermissionPopup />
    <PartnerLockup />
  </div>
</template>
